//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../../../styles/all';

.paginationContainer
{
    list-style-type: none;
    display:         flex;
    flex-direction:  row;
    gap:             10px;

    li
    {
        $size:           40px;

        user-select:     none;
        width:           $size;
        height:          $size;
        text-decoration: none;
        background:      $lightGray;
        border:          1px solid $gravelGray;
        cursor:          pointer;
        transition:      opacity .1s ease-in-out, background .1s ease-in-out;

        &:global(.disabled)
        {
            opacity: 0.5 !important;
            cursor:  not-allowed;
        }

        &:global(.previous),
        &:global(.next)
        {
            @include svgColor($black)
        }

        a
        {
            width:           100%;
            height:          100%;
            display:         flex;
            justify-content: center;
            align-items:     center;

            svg
            {
                $svgSize: 20px;

                width:    $svgSize;
                height:   $svgSize;
                @include svgColor($black);
            }
        }

    }
}
