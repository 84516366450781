//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.objectPoolsScreen
{
    display:        flex;
    flex-direction: column;
    gap:            20px;

    h2
    {
        margin-bottom: 20px;
    }

    .objectPoolsWrapper
    {
        margin-top:            40px;
        display:               grid;
        grid-template-columns: repeat(1, 1fr);
        gap:                   10px;

        @media (min-width: $screen-sm)
        {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (min-width: $screen-md)
        {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (min-width: $screen-lg)
        {
            grid-template-columns: repeat(4, 1fr);
        }

        .objectPoolItem
        {
            background:     $lightGray;
            border:         1px solid $gray;
            display:        flex;
            flex-direction: column;
            padding:        20px;
            height:         auto;

            img
            {
                width:           100%;
                height:          100px;
                object-fit:      contain;
                object-position: left;
                margin-bottom:   20px;
            }

            p
            {
                margin-bottom: 10px;
            }

            .contactWrapper
            {
                margin-top:     10px;
                display:        flex;
                flex-direction: column;
                gap:            10px;

                a
                {
                    display:         flex;
                    gap:             10px;
                    align-items:     center;
                    color:           $black;
                    text-decoration: none;
                    transition:      color 0.1s ease-in-out;

                    span:first-child
                    {
                        width: fit-content;

                        svg
                        {
                            width:      22px;
                            transition: color 0.1s ease-in-out;
                        }
                    }
                }
            }
        }
    }
}
